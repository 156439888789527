/**
 * Replaces colors from the document's stylesheets with new colors from
 * a given palette.
 *
 * Requires two object parameters, with corresponding property keys in each object, whosevalues are strings of valid hex colors. Reads all rulesets in the document and replaces all old colors with new colors, and then appends a style tag to the DOM.
 *
 * Dependency: Hex.js.
 *
 * @param {Object.<string>} newPalette - new colors to use
 * @param {Object.<string>} oldPalette - old colors to be replaced
 */
(function initChangePalette() {
  window.ChangePalette = { init, now };
  const { Hex } = window;

  // to be filled with length-3 arrays, each containing [0] non-global RegExp, [1] global RegExp, and [2] replacement
  const replacements = [];

  // the style tag that will get the styles
  const styleEl = document.createElement('style');
  styleEl.type = 'text/css';
  styleEl.id = 'change-palette-new-styles';

  // hide entire document while waiting for the palette
  const waitingStyleEl = document.createElement('style');
  waitingStyleEl.type = 'text/css';
  waitingStyleEl.id = 'waiting-for-palette-styles';
  waitingStyleEl.innerHTML = `  
    html.-waiting-for-palette {
      visibility: hidden;
      opacity: 0;
    }
    
    html {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.5s linear, visibility 0.5s step-start;
    }
  `;
  document.head.appendChild(waitingStyleEl);
  document.documentElement.classList.add('-waiting-for-palette');

  function showPage() {
    document.documentElement.classList.remove('-waiting-for-palette');
    document.documentElement.classList.add('-changed-palette');
  }

  function init(newPalette, oldPalette, options = {}) {
    if (!newPalette) {
      console.log('No palettes provided, showing default palette.');
      showPage();

      return false;
    }

    // default options
    const addListeners =
      'addListeners' in options ? options.addListeners : true;
    const callNow = 'callNow' in options ? options.callNow : true;

    Object.keys(oldPalette).forEach(hex => {
      if (!(hex in newPalette)) {
        console.log(
          `Replacement for ${
            oldPalette[hex]
          } not found in new palette.`
        );

        return false;
      }

      // throw on invalid hex colors
      if (!Hex.isValid(oldPalette[hex])) {
        throw new Error(
          'oldPallete has a property that is not a valid hex color.'
        );
      }

      // throw on invalid hex colors
      if (!Hex.isValid(newPalette[hex])) {
        throw new Error(
          'newPallete has a property that is not a valid hex color.'
        );
      }

      // add replacement for long hex colors
      const longHex = Hex.getLong(oldPalette[hex]);
      replacements.push([
        RegExp(longHex, 'i'),
        RegExp(longHex, 'gi'),
        newPalette[hex]
      ]);

      // add replacement for short hex colors
      const shortHex = Hex.getShort(oldPalette[hex]);
      if (shortHex) {
        replacements.push([
          RegExp(shortHex, 'i'),
          RegExp(shortHex, 'gi'),
          newPalette[hex]
        ]);
      }

      // add replacement for RGB and RGBA colors
      replacements.push([
        RegExp(`\\(${Hex.toRGB(oldPalette[hex]).join(', ')}`),
        RegExp(`\\(${Hex.toRGB(oldPalette[hex]).join(', ')}`, 'g'),
        `(${Hex.toRGB(newPalette[hex]).join(', ')}`
      ]);
    });

    if (callNow) {
      // change palettes now
      now();
    }

    if (addListeners) {
      // calls now(), but at the end of the call stack
      const nowDeferred = () => {
        setTimeout(now, 0);
      };

      // add listeners to DOM ready and window load
      document.addEventListener('DOMContentLoaded', nowDeferred);
      window.addEventListener('load', nowDeferred);

      // remove `.-waiting-for-palette` from document on load event, in case we have a problem at least they get the old palette
      window.addEventListener('load', () => {
        setTimeout(showPage, 0);
      });
    }
  }

  function now() {
    addStyleSheet();
    replaceStyleAttributes();
    showPage();
  }

  function addStyleSheet() {
    // get stylesheets as array
    const styleSheetList = document.styleSheets;

    // to be filled with all relevant CssText strings
    const replaceableCssTextList = [];

    // returns true if a CssText string contains a color to replace
    const isReplacementFoundInCssText = cssText => {
      for (let i = 0, iMax = replacements.length; i < iMax; i++) {
        // test against non-global RegExp to avoid incrementing lastIndex
        if (replacements[i][0].test(cssText)) {
          return true;
        }
      }

      return false;
    };

    // fill replaceableCssTextList with double loop
    // loop through styleSheets
    for (let i = 0, iMax = styleSheetList.length; i < iMax; i++) {
      try {
        const rules =
          styleSheetList[i].cssRules || styleSheetList[i].rules;

        // loop through cssTexts
        for (let j = 0, jMax = rules.length; j < jMax; j++) {
          try {
            const { cssText } = rules[j];

            if (isReplacementFoundInCssText(cssText)) {
              // fill replaceableCssTextList
              replaceableCssTextList.push(cssText);
            }
          } catch (e) {
            console.error(e);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    // RegExp for splitting cssText into rules
    const cssRuleSplitter = /[;{}]/g;

    // to be filled with the text of new rulesets
    const newCssTextList = [];

    for (
      let j = 0, jMax = replaceableCssTextList.length;
      j < jMax;
      j++
    ) {
      const cssText = replaceableCssTextList[j];
      const newRules = [];

      // split into rules and replace each one
      cssText.split(cssRuleSplitter).forEach((rule, k) => {
        // the first item is the selector
        if (k === 0) {
          newRules.selector = rule;
          return;
        }

        // copy the string for comparison later
        let ruleReplaced = rule;

        for (let m = 0, mMax = replacements.length; m < mMax; m++) {
          // make replacements on the copy
          ruleReplaced = ruleReplaced.replace(
            replacements[m][1],
            replacements[m][2]
          );
        }

        // only push if the string has changed
        if (rule !== ruleReplaced) {
          newRules.push(ruleReplaced);
        }
      });

      const newCssText = `${newRules.selector} {\n  ${newRules.join(
        ';\n  '
      )};\n}`;

      if (newCssText.indexOf('url(') !== -1) {
        console.warn(
          `ChangePalette has added a new CSS rule containing a URL: \n\n${newCssText}\n\nThis may cause a broken reference. Please avoid using the shorthand background property so that ChangePalette can ignore the URL.`
        );
      }

      newCssTextList.push(newCssText);
    }

    // get last <style> <link>, or child of <body> on the page
    const stylesList = document.querySelectorAll(
      'link, style, body>*:last-child'
    );
    const lastStyleEl = stylesList[stylesList.length - 1];

    // insert our <style> after it and update its content
    lastStyleEl.parentNode.insertBefore(
      styleEl,
      lastStyleEl.nextSibling
    );

    styleEl.textContent = newCssTextList.join('\n\n');

    document.documentElement.classList.remove('-waiting-for-palette');
  }

  function replaceStyleAttributes() {
    const elList = document.querySelectorAll('[style]');

    for (let i = 0, iMax = elList.length; i < iMax; i++) {
      const el = elList[i];
      const style = el.getAttribute('style');

      // copy the string for comparison later
      let styleReplaced = style;

      for (let j = 0, jMax = replacements.length; j < jMax; j++) {
        // make replacements on the copy
        styleReplaced = styleReplaced.replace(
          replacements[j][1],
          replacements[j][2]
        );
      }

      // only push if the string has changed
      if (style !== styleReplaced) {
        el.setAttribute('style', styleReplaced);
      }
    }
  }
})();
